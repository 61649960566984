var $class="se2--promo-image-block-with-inserted-asset",$name="PromoImageBlockWithInsertedAsset",$path="app/components/PromoImageBlockWithInsertedAsset/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import { controlsWrapperSelector, videoSelector } from './config';

let intersectionObserver = null;

const onControlsClick = (element) => {
  const controlsContainer = element.querySelector(controlsWrapperSelector);
  const { dataset } = controlsContainer;
  const videoElement = element.querySelector(videoSelector);
  if (dataset.paused === 'false') {
    videoElement.pause();
    dataset.paused = true;
  } else {
    videoElement.play();
    dataset.paused = false;
  }
};

const scrollToVideoObserver = (element) => {
  const options = {
    root: document.body,
    rootMargin: '0px',
    threshold: 1.0,
  };

  intersectionObserver = new IntersectionObserver(() => {
    if (!isMobileBreakpoint()) {
      element.querySelector('video')?.play();
    }
  }, options);
  intersectionObserver.observe(element);
};

const initVideo = (addEventListener, element) => {
  const controlsContainer = element.querySelector(controlsWrapperSelector);
  const { dataset } = controlsContainer;

  dataset.paused = isMobileBreakpoint();

  addEventListener(controlsContainer, 'click', onControlsClick.bind(null, element));
};

export default shell.registerComponent($this, ({ addEventListener, mount, unmount }) => {
  mount((element) => {
    if (element.querySelector('video')) {
      initVideo(addEventListener, element);
      scrollToVideoObserver(element);
    }

    lazyHandleOpenModalButtons(addEventListener, element);
  });

  unmount(() => {
    intersectionObserver?.disconnect();
  });
});
